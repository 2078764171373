import React  from 'react';
import "../assets/styles/main.scss";
import { Typography } from 'antd';
import { AgentsRootComponent } from '../components//agent-component/agent-root-component/agent-root.component';
import { connect } from 'react-redux';
import { VerifyPagePermission } from '../components/verify-page-permission-component/verify-page-permission.component';
import Permissions from "../utils/permissions-constant";
import { useSelector , useDispatch} from 'react-redux';
import { loginReducerSelector } from '../selectors/login-selectors';
import { TravelsSelect } from '../components/travels-select-component/travels-select.component';
import { useState } from 'react';
import { useEffect } from 'react';
import { getAgentsDetails , getAgentPrivileges} from '../actions/agent-actions';
import { getBranchOffices } from '../actions/address-actions';

const checkForTravelBlockToShow=(privilege)=>{
    if(Permissions.agentPrivileges.DAFIDY_USER==privilege){
      return true;
    }else{
      return false;
    }
}

const { Title } = Typography;

export const Agents = (props) => {

  const {
    userInfo
  } = useSelector(loginReducerSelector);

  const [ operatorCode , setOperatorCode] = useState(null);
  const dispatch = useDispatch();;

  useEffect(()=>{
    setOperatorCode(userInfo.operator_code);
  },[userInfo])

  useEffect(()=>{
    dispatch(getBranchOffices(operatorCode));
  },[operatorCode])

  let isShowSelectTravels=checkForTravelBlockToShow(userInfo.privilege);

  const onSelect=(travel)=>{
    setOperatorCode(travel.operator_code);
    loadData(travel.operator_code);
  }

  const loadData=(code)=>{
    dispatch(getAgentsDetails(code));
    dispatch(getAgentPrivileges(code,true));
  }

  const renderChildren=<div style={{position:'relative',top:'10px'  , margin: '20px'}}>
              {isShowSelectTravels?
                  <TravelsSelect
                  onSelect={onSelect}
                  />
                  :
                  null
              } 
              <Title level={4}>Active Office/Agents</Title>
                <AgentsRootComponent 
                  operatorCode={operatorCode}
                  loadData={loadData}
                  defaultLoadAgents={!isShowSelectTravels}
                />
            </div>

    return (
       <VerifyPagePermission 
       pagePermission={Permissions.pagePermission.ADMIN_VIEW_AGENTS_PAGE}
       renderChildren={renderChildren}
       onPermissionSuccess={()=>{

       }}
       />
    );
}



const mapReduxStateToProps = reduxState => {
    return {
    
    }
}

const mapReduxActionsToProps = dispatch => {
return {

  }
}

export const AgentsPage = connect(mapReduxStateToProps,
    mapReduxActionsToProps)(Agents);
