/**
 * Address related actions can be created here
 */

import { GET_ADDRESS_URL ,
         INSERT_ADDRESS_URL ,
         UPDATE_ADDRESS_URL ,
         GET_ADDRESS_LOCATIONS_BY_CITY_URL,
         GET_BRANCH_OFFICES_URL
       } from "../utils/api-url";
import { GET_ADDRESSES_ACTION ,
         INSERT_ADDRESS_ACTION ,
         UPDATE_ADDRESS_ACTION ,
         GET_ADDRESS_LOCATIONS_BY_CITY,
         GET_BRANCH_OFFICES
        } from "./action-types";
import { postCall } from "../utils/network.util";
import { trimString } from "../utils/utilities";


export const getAddressLocationsByCity = (cities,isForBoardingPoints)=>{
    return{
        type:GET_ADDRESS_LOCATIONS_BY_CITY,
        payload:{
            url:GET_ADDRESS_LOCATIONS_BY_CITY_URL,
            api:postCall,
            isForBoardingPoints:isForBoardingPoints,
            apiPayload:{
                operatorCode:global.operator_code,
                City:cities
            }
        }
    }
}
export const getAddressDetailsAction = () => {
    return {
        type: GET_ADDRESSES_ACTION,
        payload: {
            url: GET_ADDRESS_URL,
            api: postCall,
            apiPayload:{
                operatorCode:global.operator_code
            }
        }
    }
}


export const insertAddressDetailsAction = ({address,
    agent_user_name,
    city,
    is_office,
    landline_number,
    landmark,
    latitude,
    longitude,
    name,
    phone_number,
    precedence_number},onSuccess) => {
    return {
        type: INSERT_ADDRESS_ACTION,
        payload: {
            url: INSERT_ADDRESS_URL ,
            api: postCall,
            apiPayload:{
                location:{
                address:trimString(address),
                agent_user_name,
                city,
                is_office,
                landline_number,
                landmark:trimString(landmark),
                latitude,
                longitude,
                name:trimString(name),
                phone_number,
                precedence_number,
                operator_code:global.operator_code
            }
          },
          onSuccess:onSuccess
        }
    }
}


export const updateAddressDetailsAction  = ({address,
    id,
    agent_user_name,
    city,
    is_office,
    landline_number,
    landmark,
    latitude,
    longitude,
    name,
    phone_number,
    precedence_number},
    onSuccess
    ) => {
    return {
        type: UPDATE_ADDRESS_ACTION,
        payload: {
            url: UPDATE_ADDRESS_URL ,
            api: postCall,
            apiPayload:{
                location:{
                id,
                address:trimString(address),
                agent_user_name,
                city,
                is_office,
                landline_number,
                landmark:landmark,
                latitude,
                longitude,
                name:name,
                phone_number,
                precedence_number,
                operator_code:global.operator_code
            }
        },
        onSuccess:onSuccess
     }
   }
}

export const getBranchOffices= (operator_code) => {
    return {
        type: GET_BRANCH_OFFICES,
        payload: {
            url: GET_BRANCH_OFFICES_URL,
            api: postCall,
            apiPayload:{
                operatorCode:operator_code
            }
        }
    }
}
