import { API_BASE_URL , WEBSITE_SERVER_URL, API_B2B_ADMIN_BASE_URL} from "../app.config";

export const SAMPLE_URL = `${API_BASE_URL}/api/sampleurl`;

//locations
export const GET_LOCATIONS_INFO_URL = `${API_BASE_URL}/api/getcitiesDetails`;
export const INSERT_LOCATION_URL = `${API_BASE_URL}/api/createCity`;
export const UPDATE_LOCATION_URL = `${API_BASE_URL}/api/updateCity`;
export const DELETE_LOCATION_URL = `${API_BASE_URL}/api/deleteCity`;
export const GET_MASTER_CITIES_URL = `${API_BASE_URL}/api/getMasterCities`;

//address
export const GET_ADDRESS_URL = `${API_BASE_URL}/api/getAddressLocations`;
export const INSERT_ADDRESS_URL  = `${API_BASE_URL}/api/createLocation`;
export const UPDATE_ADDRESS_URL = `${API_BASE_URL}/api/updateLocation`;
export const GET_ADDRESS_LOCATIONS_BY_CITY_URL = `${API_BASE_URL}/api/getAddressLocations`;

//agents
export const GET_AGENTS_URL = `${API_BASE_URL}/api/getAgents`;
export const CREATE_AGENT_URL = `${API_BASE_URL}/api/createAgent`;
export const DELETE_AGENT_URL = `${API_BASE_URL}/api/deleteAgentById`;
export const UPDATE_AGENT_URL = `${API_BASE_URL}/api/updateAgent`;
export const GET_BRANCH_OFFICES_URL = `${API_BASE_URL}/api/getBranchOfficesByOperatorCode`;

//agents color
export const GET_AGENT_COLOR_URL = `${API_BASE_URL}/api/getAgentPortolColorsByOperatorCode`;
export const CREATE_AGENT_COLOR_URL = `${API_BASE_URL}/api/create`;
export const UPDATE_AGENT_COLOR_URL = `${API_BASE_URL}/api/update`; 

//cancellation policy
export const GET_CANCELLATION_POLICY_URL = `${API_BASE_URL}/api/getCancellationPolicyByOperatorCode`;
export const CREATE_CANCELLATION_POLICY_URL = `${API_BASE_URL}/api/createCancellationPolicy`;
export const UPDATE_CANCELLATION_POLICY_URL = `${API_BASE_URL}/api/updateCancellationPolicy`;
export const DELETE_CANCELLATION_POLICY_URL = `${API_BASE_URL}/api/deleteCancellationPolicy`;

//export const DELETE_ADDRESS_URL = `${API_BASE_URL}/api/deleteLocationById`;

 //buses

export const CREATE_BUS_URL= `${API_BASE_URL}/api/createBus`;
export const DELETE_BUS_URL= `${API_BASE_URL}/api/deleteBusById`;
export const UPDATE_BUS_SEAT_LAYOUT_URL= `${API_BASE_URL}/api/updateSeatLayout`
export const GET_BUS_URL = `${API_BASE_URL}/api/getBuses`;
export const GET_SEAT_LAYOUT_BY_BUS_ID_URL = `${API_BASE_URL}/api/getSeatLayoutByBusId`;
export const GET_SEAT_GROUP_IDS_URL = `${API_BASE_URL}/api/getSeatGroups`;
export const UPDATE_BUS_DETAILS_URL = `${API_BASE_URL}/api/updateBus`;
export const GET_BUS_TEMPALTE_URL = `${API_BASE_URL}/api/getBusTemplate`;
export const CLONE_BUS_AS_TEMPLATE_URL = `${API_BASE_URL}/api/createSeatLayoutTemplateByBusId`;

 //trips

 export const INSERT_TRIP_URL = `${API_BASE_URL}/api/createSchedule`;
 export const GET_ROUTES_BY_TRIP_ID_URL = `${API_BASE_URL}/api/getNewRoutesByScheduleId`;
 export const GET_SCHEDULE_BY_ID_URL = `${API_BASE_URL}/api/getScheduleById`;
 export const CREATE_ROUTES_URL = `${API_BASE_URL}/api/createRoutes`;
 export const GET_BOARDING_POINTS_BY_SCHEDULE_ID_URL = `${API_BASE_URL}/api/getPickupLocationsByScheduleId`;
 export const GET_DROP_POINTS_BY_SCHEDULE_ID_URL = `${API_BASE_URL}/api/getDropLocationsByScheduleId`;
 export const CREATE_BOARDING_POINTS_URL = `${API_BASE_URL}/api/createPickupLocation`;
 export const CREATE_DROP_POINTS_URL = `${API_BASE_URL}/api/createDropLocation`;
 export const CLONE_TRIP_URL =`${API_BASE_URL}/api/cloneSchedule`;
 export const CLONE_AS_RETURN_TRIP_URL =`${API_BASE_URL}/api/cloneAsReturnSchedule`;
 export const VALIDATE_TRIP_URL =`${API_BASE_URL}/api/validateSchedule`;
 export const REGISTER_DP_URL =`${API_BASE_URL}/api/informRedBusDPAboutScheduleRegister`;
 export const DE_REGISTER_DP_URL =`${API_BASE_URL}/api/informRedBusDPAboutScheduleDeRegister`;

 //schedules 
export const GET_IN_ACTIVE_SCHEDULES_URL = `${API_BASE_URL}/api/getSchedules`;
export const GET_ACTIVE_SCHEDULES_URL = `${API_BASE_URL}/api/getSchedules`;
export const ACTIVATE_SCHEDULES_URL = `${API_BASE_URL}/api/activateTrips`;
export const BLOCK_SCHEDULES_URL = `${API_BASE_URL}/api/blockTrips`;
export const REMOVE_SCHEDULES_URL = `${API_BASE_URL}/api/removeSchedule`;


//trips
export const GET_TRIPS_URL = `${API_BASE_URL}/api/getSchedules`;
export const DELETE_TRIP_URL = `${API_BASE_URL}/api/deleteScheduleById`;
export  const DELETE_ROUTE_BY_ID_URL = `${API_BASE_URL}/api/deleteRouteById`;
export const DELETE_BOARDING_POINT_URL = `${API_BASE_URL}/api/deletePickupLocationById`;
export const UPDATE_BOARDING_POINT_URL = `${API_BASE_URL}/api/updatePickupLocation`;
export const UPDATE_DROP_POINT_URL = `${API_BASE_URL}/api/updateDropLocation`;
export const DELETE_DROP_POINT_URL = `${API_BASE_URL}/api/deleteDropLocationById`;
export const UPDATE_TRIP_URL = `${API_BASE_URL}/api/updateSchedule`;
export const UPDATE_ROUTES_FARE_URL =`${API_BASE_URL}/api/updateRoutes`;
export const INSERT_BOARDING_POINTS_INTO_TEMPLATE_URL = `${API_BASE_URL}/api/saveBoardingPointsInTemplate`;
export const INSERT_DROP_POINTS_INTO_TEMPLATE_URL = `${API_BASE_URL}/api/saveDropPointsInTemplate`;
export const UPDATE_TRIP_ROUTE_TIMINGS_URL = `${API_BASE_URL}/api/updateRouteTimings`;
export const ACTIVATE_REDBUS_CITY_PAIR_URL = `${API_BASE_URL}/api/activateRedbusCityPair`;
export const INFORM_OTA_FOR_TRIP_CHANGES_URL = `${API_BASE_URL}/api/informOTAforTripChanges`;

 export const LOG_IN_ACTION_URL = `${API_BASE_URL}/api/agentLogin`;

 
 //Vechicles
 export const GET_VECHICLES_URL = `${API_BASE_URL}/api/vechicleInfo`;
 export const UPDATE_VECHICLES_URL = `${API_BASE_URL}/api/updateScheduleVechiclePhoneNo`;
 export const CREATE_VECHICLE_URL = `${API_BASE_URL}/api/createVechicle`;
 export const DELETE_VECHICLE_URL = `${API_BASE_URL}/api/deleteVechicle`;

 //price list 
 export const GET_SCHDULES_BY_DATE_FOR_PRICE_LIST_URL = `${API_BASE_URL}/api/getSchedulesByDateforPriceList`;
 export const GET_SCHEDULE_GROUP_URL=`${API_BASE_URL}/api/getSchedulesGroup`;
 export const CREATE_SCHEDULE_GROUP_URL=`${API_BASE_URL}/api/createSchedulesGroup`;
 export const DELETE_SCHEDULE_GROUP_URL=`${API_BASE_URL}/api/deleteSchedulesGroup`;
 export const UPDATE_SCHEDULE_GROUP_URL=`${API_BASE_URL}/api/updateSchedulesGroup`;

 //privilleage

 export const GET_USER_PRIVILLEGE_URL = `${API_BASE_URL}/api/getAgentPrivilegeActions`;
 export const CREATE_USER_PRIVILLEGE_URL = `${API_BASE_URL}/api/insertAgentPrivilegeActions`;
 export const DELETE_USER_PRIVILLEGE_URL = `${API_BASE_URL}/api/deleteAgentPrivilegeActionsByPrivilege`;
 export const UPDATE_USER_PRIVILLEGE_URL = `${API_BASE_URL}/api/updateAgentPrivilegeActions`;
 export const GET_AGENT_PRIVILEGES_URL = `${API_BASE_URL}/api/getAgentPrivileges`;

 //home page urls

 export const GET_SCHEDULES_BY_DATE_URL = `${API_BASE_URL}/api/getSchedulesByDate`;

 //Gst 
 export const GET_GST_URL = `${API_BASE_URL}/api/getGSTs`;
 export const UPDATE_GST_URL = `${API_BASE_URL}/api/updateGST`;

 //cache refresh
 export const REFRESH_CACHE_URL = `${WEBSITE_SERVER_URL}/api/refreshCache`;

 //buses 
 export const GET_SEAT_TYPES_URL = `${API_BASE_URL}/api/getSeatTypes`;
 export const CLONE_BUS_URL = `${API_BASE_URL}/api/cloneBus`;
 export const DELETE_SEAT_URL = `${API_BASE_URL}/api/deleteSeat`;
 export const UPDATE_SEAT_GROUPS_URL = `${API_BASE_URL}/api/updateSeatGroupIdsByBusId`;
 export const VALIDATE_SEAT_URL = `${API_BASE_URL}/api/validateSeatLayout`
 export const FETCH_AMENITIES_URL = `${API_BASE_URL}/api/fetchAmenities`;
 export const FETCH_AMENITIES_BY_BUS_ID_URL = `${API_BASE_URL}/api/fetchAmenitiesByBusId`;
 export const ADD_AMENITIES_TO_BUS_URL = `${API_BASE_URL}/api/addAmenitiesToBus`;
 export const GET_SEAT_LAYOUT_BY_TEMPLATE_ID_URL = `${API_BASE_URL}/api/getSeatLayoutByTemplateId`;


//bus operators
export const GET_BUS_OPERATORS_URL = `${API_BASE_URL}/api/getActiveTravels`;
export const INSERT_BUS_OPERATOR_URL = `${API_BASE_URL}/api/createTravel`;
export const DELETE_BUS_OPERATOR_URL = `${API_BASE_URL}/api/inActivateTravel`;
export const UPDATE_BUS_OPERATOR_URL = `${API_BASE_URL}/api/updateTravel`;

// travel management urls
export const GET_TRAVEL_MANAGEMENT_URL = `${API_B2B_ADMIN_BASE_URL}/api/getTravelManagements`;
export const CREATE_TRAVEL_MANAGEMENT_URL = `${API_B2B_ADMIN_BASE_URL}/api/createtravelmanagement`;
export const UPDATE_TRAVEL_MANAGEMENT_URL = `${API_B2B_ADMIN_BASE_URL}/api/updatetravelmanagement`;
export const DELETE_TRAVEL_MANAGEMENT_URL = `${API_B2B_ADMIN_BASE_URL}/api/deletetravelmanagement`;

// Operator mapping url
export const CREATE_OPERATOR_MAPPING_URL = `${API_B2B_ADMIN_BASE_URL}/api/insertUrlMappingData`;

// Demand Days url
export const GET_CITIES_URL = `${API_BASE_URL}/api/getRedbusCities`;
export const GET_DEMAND_DAYS_URL = `${API_BASE_URL}/api/fetchDemandDays`;
export const INSERT_DEMAND_DAYS_URL = `${API_BASE_URL}/api/insertDemandDays`;

// Admin Driver mapping url
export const GET_ADMIN_DRIVER_BY_OPERATOR_CODE_URL = `${API_BASE_URL}/api/getAdminDriverByOperatorCode`;
export const GET_DRIVER_MANAGEMENT_BY_OPERATOR_URL = `${API_BASE_URL}/api/getDriverManagementByOperator`;
export const ADD_ADMIN_DRIVER_URL = `${API_BASE_URL}/api/addAdminDriver`
export const GET_ALL_ADMIN_DRIVER_URL = `${API_BASE_URL}/api/fetchAllAdminDriver`;

export const SEND_OTA_INTEGRATION_URL = `${API_BASE_URL}/api/sendOTAIntegrationMail`;